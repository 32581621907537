import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home page" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Overview = _resolveComponent("Overview")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Overview, {
      type: "home",
      "default-sort": "AVAILABLE_SINCE_DESC,TITLE_ASC"
    })
  ]))
}