import { Options, Vue } from 'vue-class-component'
import { getUserWatchlistService } from '@/services/userWatchlistService'

@Options({
  props: {
    nfid: Number,
    watchlistEntry: Object
  },
  emits: [
    'updated'
  ]
})
export default class WatchlistButtons extends Vue {
  nfid!: 0n
  watchlistEntry!: Record<string, any>

  private showOwnWatchlistEntry = false
  private selectedWatchlistEntry: Record<string, unknown> = {}
  private ownWatchlistEntry: Record<string, unknown> = {}
  private disabled = true

  beforeMount () {
    // copy property to member to make it mutable
    this.selectedWatchlistEntry = this.watchlistEntry
  }

  public getIcon (): string {
    let icon = '+'
    const entry = this.getActiveWatchlistEntry()
    if (entry != null) {
      switch (entry.status) {
        case 'TO_WATCH':
          icon = '🍿'
          break
        case 'WATCHING_ALONE':
          icon = '🧑'
          break
        case 'WATCHING_TOGETHER':
          icon = '👪'
          break
        case 'STOPPED':
          icon = '🚫'
          break
        case 'FINISHED':
          icon = '✔'
          break
      }
    }

    return icon
  }

  public getStyleClass (): string {
    let styleClass = ''
    const entry = this.getActiveWatchlistEntry()
    if (entry != null) {
      styleClass = (<string> entry.status).toLowerCase()
    }
    return styleClass
  }

  public async startCompareWithOwnWatchlist () {
    if (!this.showOwnWatchlistEntry) {
      if (!getUserWatchlistService().canEditWatchlist()) {
        this.ownWatchlistEntry = await getUserWatchlistService()
          .getWatchlistEntryFromOwnWatchlist(<bigint> this.nfid)
        this.showOwnWatchlistEntry = true
        this.disabled = this.ownWatchlistEntry !== undefined && this.ownWatchlistEntry.status !== 'DELETED'
      } else {
        this.disabled = false
      }
      this.$forceUpdate()
    }
  }

  public async endCompareWithOwnWatchlist () {
    this.showOwnWatchlistEntry = false
    this.$forceUpdate()
  }

  async nextStatus () {
    if (!this.disabled) {
      const entry = this.getActiveWatchlistEntry()
      if (entry.status === '') {
        entry.status = 'TO_WATCH'
        await getUserWatchlistService().addToWatchlist(this.nfid)
      } else {
        switch (entry.status) {
          case 'TO_WATCH':
            entry.status = 'WATCHING_ALONE'
            break
          case 'WATCHING_ALONE':
            entry.status = 'WATCHING_TOGETHER'
            break
          case 'WATCHING_TOGETHER':
            entry.status = 'STOPPED'
            break
          case 'STOPPED':
            entry.status = 'FINISHED'
            entry.watchedAt = new Date().toISOString().substr(0, 10)
            break
          case 'FINISHED':
            entry.status = 'DELETED'
            break
          case 'DELETED':
            entry.status = 'TO_WATCH'
            break
        }
        await getUserWatchlistService().updateWatchlist(this.nfid, entry)
      }
      this.setActiveWatchlistEntry(entry)
      this.$emit('updated', entry.status)
    }
  }

  private getActiveWatchlistEntry (): any {
    let entry = this.showOwnWatchlistEntry ? this.ownWatchlistEntry : this.selectedWatchlistEntry
    if (entry === undefined || entry === null) {
      entry = {}
      entry.nfid = this.nfid
      entry.status = ''
    }
    return entry
  }

  private setActiveWatchlistEntry (entry: Record<string, any>) {
    if (this.showOwnWatchlistEntry) {
      this.ownWatchlistEntry = entry
    } else {
      this.selectedWatchlistEntry = entry
    }
  }
}
