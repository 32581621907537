import { TwitterService } from '@/services/twitterService'

enum TweetType {
  New,
  Expiring,
  Soon,
  Suggestion
}

/**
 * Implementation of the TwitterService interface that uses the default
 * Vue.js http functionality to access the Twitter intent API.
 *
 * See 'https://emojipedia.org/' for emoticons.
 */
export class HttpTwitterService implements TwitterService {
  static WEEK_IN_MILLIS = 7 * 24 * 60 * 60 * 1000

  createTweet (title: any): string {
    const type = this.determineTweetType(title)
    const header = this.createHeader(type)
    const titleDetails = this.formatTitleReleaseYear(title) +
      this.formatRuntime(title) +
      this.formatDate(type, title) +
      this.formatRating(title) +
      '\n' +
      this.formatPersons('🎬', title.directors, 3) +
      this.formatPersons('🖋️', title.writers, 3) +
      this.formatPersons('🎭', title.actors, 10) +
      '\n' +
    this.formatUrl(title) +
      '\n\n'

    return 'text=' + encodeURIComponent(header + titleDetails) +
      '&hashtags=' + this.createHashtags(type, title)
  }

  private createHeader (type: TweetType) {
    switch (type) {
      case TweetType.New:
        return 'Nieuw op Netflix:\n\n'
      case TweetType.Soon:
        return 'Binnenkort op Netflix:\n\n'
      case TweetType.Expiring:
        return 'Verdwijnt binnenkort van Netflix:\n\n'
      case TweetType.Suggestion:
        return 'Netflix kijktip:\n\n'
    }
  }

  private createHashtags (type: TweetType, title: any): string {
    switch (type) {
      case TweetType.Expiring:
        return 'VerwijderAlarm'
      case TweetType.New:
        return 'NieuwOpNetflix'
      case TweetType.Soon:
        return 'BinnenkortOpNetflix'
      default:
        return 'Kijktip'
    }
  }

  private formatTitleReleaseYear (title: any): string {
    const label = title.category === 'SHOW' ? '📺 ' : '📽️ '
    return label + title.title + ' (' + title.releaseYear + ')\n'
  }

  private formatRuntime (title: any): string {
    if (title.category === 'SHOW') {
      return '⏱ ' + title.seasonCount + ' seizoen' + (title.seasonCount > 1 ? 'en' : '') + ', ' +
        title.episodeCount + ' aflevering' + (title.episodeCount > 1 ? 'en' : '') + '\n'
    }
    const hours = Math.floor(title.runtime / 3600)
    const minutes = Math.floor(title.runtime % 3600 / 60)
    const seconds = Math.floor(title.runtime % 3600 % 60)
    return '⏱ ' + hours + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2) + '\n'
  }

  private formatDate (type: TweetType, title: any): string {
    const locale = 'nl-NL'
    if (type === TweetType.Expiring) {
      return '⏳ ' + new Date(title.availability.availableUntil)
        .toLocaleString(locale, { day: 'numeric', month: 'long', year: 'numeric' }) + '\n'
    }
    return '📆 ' + new Date(title.availability.availableSince)
      .toLocaleString(locale, { day: 'numeric', month: 'long', year: 'numeric' }) + '\n'
  }

  private formatRating (title: any): string {
    if (title.imdbRating && title.imdbRating.rating > 0) {
      return '⭐ ' + title.imdbRating.rating / 10 + '\n'
    }
    return ''
  }

  private formatPersons (label: string, persons: string[], max: number): string {
    if (persons.length > 0) {
      let text = label + ' '
      let index = 0
      while (index < max && persons.length > index) {
        if (index > 0) {
          text += ', '
        }
        text += persons[index++]
      }
      return text + '\n'
    }

    return ''
  }

  private formatUrl (title: any): string {
    return '🔗 https://www.netflix.com/title/' + title.nfid
  }

  private determineTweetType (title: any): TweetType {
    if (title.availability.availableUntil != null) {
      return TweetType.Expiring
    } else {
      const availableSince = new Date(title.availability.availableSince).getTime()
      const now = Date.now()
      if (availableSince > now) {
        return TweetType.Soon
      } else if (availableSince > now - HttpTwitterService.WEEK_IN_MILLIS) {
        return TweetType.New
      }
    }
    return TweetType.Suggestion
  }
}
