import { getSecurityManager } from '@/security/securityManager'
import { NetflixTitlesService } from '@/services/netflixTitlesService'
import { getUserWatchlistService } from '@/services/userWatchlistService'

/**
 * Implementation of the NetflixTitlesService interface that uses the default
 * Vue.js http functionality to access the REST API.
 */
export class HttpNetflixTitlesService implements NetflixTitlesService {
  static CONFIG_URL = '/config/84flix-api.json'
  static SOON_DAYS = 28

  private netflixTitlesBaseUrl = null
  private page = 0
  private searchCriteria = ''
  private genresFromDate = new Date()
  private genresToDate = new Date()
  private titles = []
  private moreAvailable = true
  private watchlistOnly = false

  /**
   * Resets the search criteria.
   */
  public init (
    type: string,
    title: string,
    category: string,
    releaseYearFrom: number,
    releaseYearTo: number,
    includeGenres: number[],
    excludeGenres: number[],
    activeOnly: boolean,
    personNfid: bigint,
    sortBy: string) {
    this.page = 0
    this.titles = []
    this.moreAvailable = true
    this.watchlistOnly = false
    switch (type) {
      case 'expiring': {
        this.searchCriteria = `&availableUntilRange.from=${this.dateToString(new Date())}&availableUntilRange.to=2100-01-01`
        this.genresFromDate = new Date(1900, 1, 1)
        this.genresToDate = new Date()
        break
      }
      case 'soon': {
        const fromDate = new Date()
        fromDate.setDate(fromDate.getDate() + 1)
        const toDate = new Date(fromDate)
        toDate.setDate(fromDate.getDate() + HttpNetflixTitlesService.SOON_DAYS)
        this.searchCriteria = `&availableSinceRange.from=${this.dateToString(fromDate)}&availableSinceRange.to=${this.dateToString(toDate)}`
        this.genresFromDate = fromDate
        this.genresToDate = toDate
        break
      }
      case 'watchlist': {
        this.genresFromDate = new Date(1900, 1, 1)
        this.genresToDate = new Date(2100, 1, 1)
        const toDate = new Date()
        if (activeOnly) {
          this.searchCriteria = ''
        } else {
          toDate.setDate(toDate.getDate() + HttpNetflixTitlesService.SOON_DAYS)
          this.searchCriteria = `&availableUntilRange.from=1900-01-01&availableSinceRange.to=${this.dateToString(toDate)}`
        }
        this.watchlistOnly = true
        break
      }
      case 'persons': {
        this.genresFromDate = new Date(1900, 1, 1)
        this.genresToDate = new Date(2100, 1, 1)
        this.searchCriteria = `&person.nfid=${personNfid}`
        if (!activeOnly) {
          const toDate = new Date()
          toDate.setDate(toDate.getDate() + HttpNetflixTitlesService.SOON_DAYS)
          this.searchCriteria += `&availableUntilRange.from=1900-01-01&availableSinceRange.to=${this.dateToString(toDate)}`
        }
        break
      }
      default: {
        this.searchCriteria = `&availableSinceRange.to=${this.dateToString(new Date())}`
        this.genresFromDate = new Date(1900, 1, 1)
        this.genresToDate = new Date()
        break
      }
    }

    this.searchCriteria += '&title=' + title +
      '&category=' + category +
      '&releaseYearRange.from=' + releaseYearFrom +
      '&releaseYearRange.to=' + releaseYearTo +
      '&includeGenres=' + includeGenres +
      '&excludeGenres=' + excludeGenres +
      '&sortCriteria=' + sortBy
  }

  /**
   * Loads the next page of search results.
   */
  public async loadNextPage () {
    if (!this.moreAvailable) {
      return this.titles
    }

    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken(), watchlistFilter: '' }
    const queryParameters = 'pageSize=84&pageNumber=' + this.page + this.searchCriteria
    if (this.watchlistOnly) {
      const nfids: Map<bigint, any> = await getUserWatchlistService().getWatchlist()
      if (Object.entries(nfids).length === 0) {
        // add dummy nfid to force searching on nfids even if the watchlist is empty
        headers.watchlistFilter += '0'
      }
      headers.watchlistFilter += Object.keys(nfids)
    }
    const httpResponse = await fetch(this.netflixTitlesBaseUrl + 'titles?' + queryParameters, { headers })
    if (this.page++ === 0) {
      this.titles = []
    }
    const page = await httpResponse.json()
    if (page.length === 0) {
      this.moreAvailable = false
    }
    this.titles = this.titles.concat(page)
    return this.titles
  }

  public async loadDetails (nfid: bigint) {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const httpResponse = await fetch(this.netflixTitlesBaseUrl + 'titles/' + nfid, { headers })
    return httpResponse.json()
  }

  public async loadAllGenres () {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const url = `${this.netflixTitlesBaseUrl}titles/genres?from=${this.dateToString(this.genresFromDate)}&to=${this.dateToString(this.genresToDate)}`
    const httpResponse = await fetch(url, { headers })
    return httpResponse.json()
  }

  private async checkConfig () {
    if (this.netflixTitlesBaseUrl == null) {
      await this.loadConfig(HttpNetflixTitlesService.CONFIG_URL)
    }
  }

  private async loadConfig (configUrl: string) {
    const httpResponse = await fetch(configUrl)
    const json = await httpResponse.json()
    this.netflixTitlesBaseUrl = json['netflix-titles-base-url']
  }

  private dateToString (date: Date): string {
    return date.toISOString().substr(0, 10)
  }
}
