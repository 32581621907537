import { Options, Vue } from 'vue-class-component'
import { getUserWatchlistService } from '@/services/userWatchlistService'
import { getSecurityManager } from '@/security/securityManager'

@Options({
  props: {
    active: Boolean
  },
  emits: [
    'closed'
  ]
})
export default class WatchlistManager extends Vue {
  active!: false
  loading = false
  watchlistFollowers: string[] = []
  nonFollowers: string[] = []
  addedFollower = ''
  deletedFollower = ''

  async beforeUpdate () {
    if (this.active && !this.loading) {
      this.loadFollowers()
    }
  }

  public async addFollower () {
    await getUserWatchlistService().addFollower(this.addedFollower)
    await this.loadFollowers()
  }

  public async deleteFollower () {
    await getUserWatchlistService().removeFollower(this.deletedFollower)
    await this.loadFollowers()
  }

  public close () {
    this.loading = false
    this.$emit('closed')
  }

  private async loadFollowers () {
    this.loading = true
    this.watchlistFollowers = await getUserWatchlistService().getWatchlistFollowers()
    this.nonFollowers = await getUserWatchlistService().getAllWatchlistUsers()
    const currentUser = getSecurityManager().getUsername()
    this.nonFollowers = this.nonFollowers
      .filter(user => !this.watchlistFollowers.includes(user) && user !== currentUser)
  }
}
