<template>
  <div id="nav">
    <router-link to="/" class="logo">
      <img alt="84flix logo" src="./assets/logo.png" class="logo">
    </router-link>
    <div class="menu">
      <router-link to="/">Home</router-link> |
      <router-link to="/expiring-soon" id="expiring">Expiring</router-link> |
      <router-link to="/coming-soon" id="coming-soon">Soon</router-link> |
      <router-link to="/watchlist" id="coming-soon">Watchlist</router-link>
    </div>
    <router-link to="/logout" class="logout">
      <img alt="84flix logout" src="./assets/logout.png" class="logout">
    </router-link>
  </div>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style lang="scss">
body {
  background-color: black;
}

.noscroll {
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;

  overflow: hidden;
  background-color: dodgerblue;

  .menu {
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 30px;
  }

  a {
    font-weight: bold;
    font-size: larger;
    color: #ffee00;
    display: inline-block;

    &.router-link-exact-active {
      color: white;
    }
  }

  .logo {
    float: left;
  }

  .logout {
    float: right;
  }

  a.logo {
    margin: 15px 0px 15px 15px;
  }

  a.logout {
    margin: -30px 15px 15px 15px;
  }

  img.logo {
    max-height: 60px;
  }

  img.logout {
    max-height: 40px;
  }

}

.page {
  padding-top: 100px;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

select, input {
  background: #222222;
  color: white;
  font-size: larger;
  border: 1px solid gray;
}

@media (max-height: 300px) {
  #nav {
    display: none;
  }
  .page {
    padding-top: 10px;
  }
}

</style>
