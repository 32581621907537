import { HttpNetflixTitlesService } from '@/services/httpNetflixTitlesService'

/**
 * Interface for services that can retrieve Netflix titles via the REST API.
 */
export interface NetflixTitlesService {
  /**
   * Initializes the search criteria.
   */
  init: (
    type: string,
    title: string,
    category: string,
    releaseYearFrom: number,
    releaseYearTo: number,
    includeGenres: number[],
    excludeGenres: number[],
    activeOnly: boolean,
    personNfid: bigint,
    sortBy: string) => void;

  /**
   * Loads the next page of search results.
   */
  // eslint-disable-next-line
  loadNextPage: () => Promise<any>;

  /**
   * Loads the details of the title with the given nfid.
   */
  // eslint-disable-next-line
  loadDetails: (nfid: bigint) => Promise<any>;

  /**
   * Loads all available genres.
   */
  // eslint-disable-next-line
  loadAllGenres: () => Promise<any>;
}

const netflixTitlesService = new HttpNetflixTitlesService()

/**
 * Returns the service for retrieving Netflix titles.
 */
export function getNetflixTitlesService (): NetflixTitlesService {
  return netflixTitlesService
}
