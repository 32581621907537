import Keycloak from 'keycloak-js'
import { NavigationGuardNext } from 'vue-router'
import { SecurityManager } from '@/security/securityManager'

/**
 * Implementation of the SecurityManager interface that uses Keycloak as the
 * authentication service.
 */
export class KeycloakSecurityManager implements SecurityManager {
  static CONFIG_URL = '/config/keycloak.json'
  static TOKEN_KEY = 'kc_token'
  static REFRESH_TOKEN_KEY = 'kc_refreshToken'
  static EMPTY = ''

  private keycloakAuth: Keycloak.KeycloakInstance

  /**
   * Creates a new instance using the configuration found in the 'keycloak.json'
   * configuration file.
   */
  constructor () {
    this.keycloakAuth = Keycloak(KeycloakSecurityManager.CONFIG_URL)
  }

  /**
   * Indicates if the user is successfully authenticated.
   */
  public isAuthenticated () {
    return this.keycloakAuth.authenticated !== undefined && this.keycloakAuth.authenticated
  }

  /**
   * Redirects the user to the login page and takes care of the communication
   * with the authentication service.
   *
   * @param next The next page to redirect to after the user is logged in.
   */
  public login (next: NavigationGuardNext) {
    console.log('login')
    const token = localStorage.getItem(KeycloakSecurityManager.TOKEN_KEY) || KeycloakSecurityManager.EMPTY
    const refreshToken = localStorage.getItem(KeycloakSecurityManager.REFRESH_TOKEN_KEY) || KeycloakSecurityManager.EMPTY

    this.keycloakAuth.init({ onLoad: 'login-required', checkLoginIframe: false, token: token, refreshToken: refreshToken })
      .then((authenticated) => {
        if (!authenticated) {
          console.error('Login failed!')
          this.resetLoginFlow()
        } else {
          localStorage.setItem(KeycloakSecurityManager.TOKEN_KEY, this.keycloakAuth.token || KeycloakSecurityManager.EMPTY)
          localStorage.setItem(KeycloakSecurityManager.REFRESH_TOKEN_KEY, this.keycloakAuth.refreshToken || KeycloakSecurityManager.EMPTY)
          setInterval(() => this.refreshToken(), 60000)
          // load user profile before redirecting to the target page
          this.keycloakAuth.loadUserProfile().finally(() => next())
        }
      })
      .catch(reason => {
        console.error('Login failed! ' + reason)
        this.resetLoginFlow()
      })
  }

  /**
   * Returns the username of the logged in user.
   */
  public getUsername (): string | undefined {
    return this.keycloakAuth.profile?.username
  }

  public logout () {
    localStorage.setItem(KeycloakSecurityManager.TOKEN_KEY, KeycloakSecurityManager.EMPTY)
    localStorage.setItem(KeycloakSecurityManager.REFRESH_TOKEN_KEY, KeycloakSecurityManager.EMPTY)
    this.keycloakAuth.clearToken()
    this.keycloakAuth.logout()
  }

  /**
   * Returns the JWT token
   */
  public getJwtToken (): string | undefined {
    return this.keycloakAuth.token
  }

  private refreshToken () {
    this.keycloakAuth.updateToken(15)
      .then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed!')
          localStorage.setItem(KeycloakSecurityManager.TOKEN_KEY, this.keycloakAuth.token || '')
        }
      }).catch(reason => {
        console.error('Failed to refresh token! ' + reason)
        this.resetLoginFlow()
      })
  }

  private resetLoginFlow () {
    localStorage.setItem(KeycloakSecurityManager.TOKEN_KEY, KeycloakSecurityManager.EMPTY)
    localStorage.setItem(KeycloakSecurityManager.REFRESH_TOKEN_KEY, KeycloakSecurityManager.EMPTY)
    this.keycloakAuth.clearToken()
    window.location.reload()
  }
}
