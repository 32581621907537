import { HttpTwitterService } from '@/services/httpTwitterService'

/**
 * Interface for services that can create Tweets.
 */
export interface TwitterService {
  /**
   * Creates a Tweet for the given title.
   *
   * @param title The title for which to create the tweet.
   */
  createTweet: (title: any) => string;
}

const twitterService = new HttpTwitterService()

/**
 * Returns the service for creating Tweets.
 */
export function getTwitterService (): HttpTwitterService {
  return twitterService
}
