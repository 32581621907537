import { HttpUserWatchlistService } from '@/services/httpUserWatchlistService'

export enum WatchlistMode {
  NONE = 'NONE',
  ALL = 'ALL',
  TO_WATCH = 'TO_WATCH',
  WATCHING_ALONE = 'WATCHING_ALONE',
  WATCHING_TOGETHER = 'WATCHING_TOGETHER',
  WATCHING = 'WATCHING_ALONE,WATCHING_TOGETHER',
  NOT_WATCHED = 'TO_WATCH,WATCHING_ALONE,WATCHING_TOGETHER',
  FINISHED = 'FINISHED',
  STOPPED = 'STOPPED',
  WATCHED = 'FINISHED,STOPPED',
  DELETED = 'DELETED'
}

/**
 * Interface for services that can retrieve watchlist users via the REST API.
 */
export interface UserWatchlistService {

  /**
   * Sets the watchlist mode.
   *
   * @param mode The watchlist mode.
   */
  setWatchlistMode: (mode: WatchlistMode) => void;

  /**
   * Returns the current watchlist user.
   */
  getCurrentWatchlistUser: () => string;

  /**
   * Sets the watchlist user.
   * @param user The watchlist user.
   */
  setCurrentWatchlistUser: (user: string) => void;

  /**
   * Returns the list of users for which the logged in user can access the watchlist.
   */
  getWatchlistUsers: () => Promise<string[]>;

  /**
   * Returns the list of followers that can access the current users watchlist.
   */
  getWatchlistFollowers: () => Promise<string[]>;

  /**
   * Returns the list of all watchlist users.
   */
  getAllWatchlistUsers: () => Promise<string[]>;

  /**
   * Add the given follower to the list of followers.
   *
   * @param follower The follower to add.
   */
  addFollower: (follower: string) => Promise<void>;

  /**
   * Removes the given follower to the list of followers.
   *
   * @param follower The follower to remove.
   */
  removeFollower: (follower: string) => Promise<void>;

  /**
   * Idicates if the user can edit the watchlist of the current watchlist user.
   */
  canEditWatchlist: () => boolean;

  /**
   * Retrieves the watchlist of the current user.
   */
  // eslint-disable-next-line
  getWatchlist: () => Promise<Map<bigint, any>>;

  /**
   * Retrieves the watchlist entry with the given nfid from the selected user's watchlist.
   */
  getWatchlistEntry: (nfid: bigint) => Promise<any>;

  /**
   * Retrieves the watchlist entry with the given nfid from the logged in users watchlist.
   */
  getWatchlistEntryFromOwnWatchlist: (nfid: bigint) => Promise<any>;

  /**
   * Adds the title with the given nfid to the watchlist.
   *
   * @param nfid The nfid of the title to add to the watchlist.
   */
  addToWatchlist: (nfid: bigint) => Promise<bigint>;
  /**
   * Updates the title with the given nfid int the watchlist.
   *
   * @param nfid The nfid of the title to update in the watchlist.
   * @param watchlistEntry The new values for the watchlist entry.
   */
  updateWatchlist: (nfid: bigint, watchlistEntry: any) => Promise<bigint>;
}

const userWatchlistService = new HttpUserWatchlistService()

/**
 * Returns the service for retrieving watchlists for users.
 */
export function getUserWatchlistService (): HttpUserWatchlistService {
  return userWatchlistService
}
