import { Options, Vue } from 'vue-class-component'
import { getNetflixTitlesService } from '@/services/netflixTitlesService'
import { getUserWatchlistService, WatchlistMode } from '@/services/userWatchlistService'
import TitleDetails from '@/components/TitleDetails.vue'
import WatchlistButtons from '@/components/WatchlistButtons.vue'
import WatchlistSelector from '@/components/WatchlistSelector.vue'

import _ from 'lodash'

@Options({
  props: {
    type: String,
    showAvailableSince: Boolean,
    defaultSort: String,
    personId: String
  },
  components: {
    TitleDetails,
    WatchlistButtons,
    WatchlistSelector
  }
})
export default class Overview extends Vue {
  type!: string
  showAvailableSince!: boolean
  defaultSort!: string
  personId!: string
  title = ''
  category = ''
  releaseYearFrom = 1900
  releaseYearTo = new Date().getFullYear()
  titles = []
  activeNfid = 0n
  showDetails = false
  genres = []
  includeGenres: number[] = []
  excludeGenres: number[] = []
  sortBy = this.defaultSort
  includeWatchlist = true
  activeOnlyInWatchlist = true
  watchlistMode = WatchlistMode.ALL
  watchlist: { [key: number]: any } = {}
  watchlistSize = 0

  private loading = false

  async beforeMount () {
    await this.init()
  }

  mounted () {
    this.scroll()
  }

  reset () {
    this.title = ''
    this.category = ''
    this.releaseYearFrom = 1900
    this.releaseYearTo = new Date().getFullYear()
    this.includeGenres = []
    this.excludeGenres = []
    this.sortBy = this.defaultSort
    this.includeWatchlist = true
    this.activeOnlyInWatchlist = true
    this.watchlistMode = WatchlistMode.ALL
    this.init()
  }

  search = _.debounce(() => this.init(), 300)

  activeTitle (nfid: bigint) {
    return this.activeNfid === nfid
  }

  showTitle (nfid: number) {
    if (this.type === 'watchlist') {
      return this.watchlist[nfid] != null
    } else {
      return this.watchlist[nfid] == null || this.includeWatchlist
    }
  }

  getKey (nfid: number) {
    if (this.watchlist[nfid] == null) {
      return nfid
    }
    return nfid + '-' + this.watchlist[nfid].status
  }

  closePopup () {
    this.showDetails = false
    this.activeNfid = 0n
    getUserWatchlistService().getWatchlist()
      .then(response => { this.watchlist = response })
  }

  private async init () {
    this.loading = false
    getUserWatchlistService().setWatchlistMode(this.watchlistMode)
    getNetflixTitlesService().init(
      this.type,
      this.title,
      this.category,
      this.releaseYearFrom,
      this.releaseYearTo,
      this.includeGenres,
      this.excludeGenres,
      this.activeOnlyInWatchlist,
      this.personId === undefined ? 0n : BigInt(this.personId),
      this.sortBy)

    await this.loadTitles()
    if (this.genres.length === 0) {
      await this.loadGenres()
    }
    this.loadWatchlist()
  }

  private loadTitles () {
    if (!this.loading) {
      this.loading = true
      getNetflixTitlesService().loadNextPage().then((response) => {
        this.titles = response
      }).catch((exception) => {
        console.error('Failed to load titles! Details: ' + exception)
        this.titles = []
      }).finally(() => {
        this.loading = false
      })
    }
  }

  private async changeWatchlistUser () {
    await this.loadWatchlist()
    if (this.type === 'watchlist') {
      this.init()
    }
  }

  private async loadWatchlist () {
    this.watchlist = await getUserWatchlistService().getWatchlist()
    this.watchlistSize = Object.keys(this.watchlist).length
  }

  private async loadGenres () {
    getNetflixTitlesService().loadAllGenres().then((response) => {
      this.genres = response
    }).catch((exception) => {
      console.error('Failed to load genres! Details: ' + exception)
      this.genres = []
    })
  }

  private scroll () {
    window.onscroll = () => {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight

      if (bottomOfWindow) {
        this.loadTitles()
      }
    }
  }
}
