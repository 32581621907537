import { Options, Vue } from 'vue-class-component'
import { getNetflixTitlesService } from '@/services/netflixTitlesService'
import { getUserWatchlistService } from '@/services/userWatchlistService'
import { getTwitterService } from '@/services/twitterService'
import WatchlistButtons from '@/components/WatchlistButtons.vue'
import WatchlistEntryDetails from '@/components/WatchlistEntryDetails.vue'

@Options({
  props: {
    nfid: Number,
    active: Boolean
  },
  components: {
    WatchlistButtons,
    WatchlistEntryDetails
  },
  emits: [
    'closed'
  ]
})
export default class TitleDetails extends Vue {
  nfid!: bigint
  active!: false
  loading = false
  titleDetails: any = {}
  selectedSeason = 0
  watchlistEntry: Record<string, unknown> = {}

  async beforeUpdate () {
    if (this.active && !this.loading && this.nfid !== 0n) {
      // disable scrolling of underlying overview page
      document.body.className = 'noscroll'
      this.loading = true
      const id: bigint = this.nfid
      this.watchlistEntry = await getUserWatchlistService().getWatchlistEntry(id)
      if (this.watchlistEntry === undefined) {
        this.watchlistEntry = {}
        this.watchlistEntry.status = ''
      }
      this.titleDetails = await getNetflixTitlesService().loadDetails(id)
      if (this.titleDetails.category === 'SHOW' &&
        this.titleDetails.seasons != null &&
        this.titleDetails.seasons.length > 0) {
        this.selectedSeason = this.titleDetails.seasons[0].seasonNumber
      }
    }
  }

  beforeUnmount () {
    // re-enable scrolling of overview page
    document.body.className = ''
  }

  public getPersonLink (name: string): string {
    // eslint-disable-next-line
    const persons: any[] = this.titleDetails.persons
    const nfid = persons.filter(x => x.name === name)[0].nfid
    return `/#/persons/${nfid}`
  }

  updateWatchlistEntryStatus (status: string) {
    this.watchlistEntry.status = status
    if (status === 'TO_WATCH') {
      this.watchlistEntry.addedAt = new Date().toISOString().substr(0, 10)
    }
  }

  createTweet (): string {
    return getTwitterService().createTweet(this.titleDetails)
  }

  public runtime () {
    if (this.titleDetails.category === 'MOVIE' && this.titleDetails.runtime > 0) {
      return Math.floor(this.titleDetails.runtime / 60) + ' min.'
    }
    return ''
  }

  public close () {
    document.body.className = ''
    this.loading = false
    this.selectedSeason = 0
    this.titleDetails = {}
    this.$emit('closed')
  }
}
