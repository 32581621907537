import { Options, Vue } from 'vue-class-component'
import WatchlistManager from '@/components/WatchlistManager.vue'

import { getUserWatchlistService } from '@/services/userWatchlistService'

@Options({
  components: {
    WatchlistManager
  },
  emits: [
    'changed'
  ]
})
export default class WatchlistSelector extends Vue {
  currentWatchlistUser = ''
  watchlistUsers: string[] = []
  showManager = false

  async beforeMount () {
    this.currentWatchlistUser = getUserWatchlistService().getCurrentWatchlistUser()
    this.watchlistUsers = await getUserWatchlistService().getWatchlistUsers()
  }

  refresh () {
    getUserWatchlistService().setCurrentWatchlistUser(this.currentWatchlistUser)
    this.$emit('changed')
  }

  canManageFollowers () {
    return getUserWatchlistService().canEditWatchlist()
  }
}
