import { getSecurityManager } from '@/security/securityManager'
import { UserWatchlistService, WatchlistMode } from '@/services/userWatchlistService'

/**
 * Implementation of the UserWatchlistService interface that uses the default
 * Vue.js http functionality to access the REST API.
 */
export class HttpUserWatchlistService implements UserWatchlistService {
  static CONFIG_URL = '/config/84flix-api.json'

  private userWatchlistBaseUrl = null
  // eslint-disable-next-line
  private watchlist: Map<bigint, any> = new Map()
  private initialized = false
  private watchlistMode = WatchlistMode.NONE
  private currentWatchlistUser: undefined | string
  private watchlistUsers: string[] = []

  public setWatchlistMode (mode: WatchlistMode) {
    this.initialized = this.watchlistMode === mode
    this.watchlistMode = mode
  }

  public getCurrentWatchlistUser (): string {
    if (this.currentWatchlistUser === undefined) {
      this.currentWatchlistUser = getSecurityManager().getUsername()
    }
    return <string> this.currentWatchlistUser
  }

  public setCurrentWatchlistUser (user: string) {
    this.initialized = this.currentWatchlistUser === user
    this.currentWatchlistUser = user
  }

  public async getWatchlistFollowers (): Promise<string[]> {
    return this.findWatchlistFollowers()
  }

  public async getAllWatchlistUsers (): Promise<string[]> {
    return this.findAllWatchlistUsers()
  }

  public async addFollower (follower: string) {
    this.initialized = false
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getSecurityManager().getJwtToken() },
      body: follower
    }
    await fetch(this.createUrl(getSecurityManager().getUsername()) + '/followers', requestOptions)
  }

  public async removeFollower (follower: string) {
    this.initialized = false
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    }
    await fetch(this.createUrl(getSecurityManager().getUsername()) + '/followers/' + follower, requestOptions)
  }

  public async getWatchlistUsers (): Promise<string[]> {
    if (this.watchlistUsers.length === 0) {
      this.watchlistUsers = await this.findWatchlistFollowees()
      this.watchlistUsers.push(<string> getSecurityManager().getUsername())
    }
    return this.watchlistUsers
  }

  public canEditWatchlist (): boolean {
    return this.currentWatchlistUser === <string> getSecurityManager().getUsername()
  }

  // eslint-disable-next-line
  public async getWatchlist (): Promise<Map<bigint, any>> {
    if (!this.initialized) {
      await this.loadWatchlist()
    }
    return this.watchlist
  }

  public async getWatchlistEntry (nfid: bigint): Promise<any> {
    return this.getWatchlistEntryForUser(nfid, this.getCurrentWatchlistUser())
  }

  public async getWatchlistEntryFromOwnWatchlist (nfid: bigint): Promise<any> {
    return this.getWatchlistEntryForUser(nfid, getSecurityManager().getUsername())
  }

  public async addToWatchlist (nfid: bigint): Promise<bigint> {
    this.initialized = false
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getSecurityManager().getJwtToken() },
      body: JSON.stringify({ nfid: nfid })
    }
    return await fetch(this.createUrl(getSecurityManager().getUsername()), requestOptions)
      .then(response => response.json())
  }

  public async updateWatchlist (nfid: bigint, watchlistEntry: any): Promise<bigint> {
    this.initialized = false
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getSecurityManager().getJwtToken() },
      body: JSON.stringify({
        nfid: nfid,
        addedAt: watchlistEntry.addedAt,
        status: watchlistEntry.status,
        watchedAt: watchlistEntry.watchedAt,
        rating: watchlistEntry.rating,
        review: watchlistEntry.review,
        privateEntry: watchlistEntry.privateEntry
      })
    }
    return await fetch(this.createUrl(getSecurityManager().getUsername()) + '/' + nfid, requestOptions)
      .then(response => nfid)
  }

  private async getWatchlistEntryForUser (nfid: bigint, user = this.getCurrentWatchlistUser()): Promise<any> {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const url = this.createUrl(user) + '/' + nfid

    return await fetch(url, { headers })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
      })
  }

  // eslint-disable-next-line
  private async loadWatchlist (): Promise<any> {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    let url = this.createUrl()
    if (this.watchlistMode !== WatchlistMode.ALL) {
      url += '?status=' + WatchlistMode[this.watchlistMode as keyof typeof WatchlistMode]
    }
    const httpResponse = await fetch(url, { headers })
    // eslint-disable-next-line
    const entries: any[] = await httpResponse.json()
    this.watchlist = entries.reduce(function (map, obj) {
      map[obj.nfid] = obj
      return map
    }, {})
    this.initialized = true
    return this.watchlist
  }

  private async findAllWatchlistUsers (): Promise<string[]> {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const url = this.userWatchlistBaseUrl + 'watchlists'

    const httpResponse = await fetch(url, { headers })
    return await httpResponse.json()
  }

  private async findWatchlistFollowers (): Promise<string[]> {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const url = this.createUrl() + '/followers'

    const httpResponse = await fetch(url, { headers })
    return await httpResponse.json()
  }

  private async findWatchlistFollowees (): Promise<string[]> {
    await this.checkConfig()
    const headers = { Authorization: 'Bearer ' + getSecurityManager().getJwtToken() }
    const url = this.createUrl() + '/followees'

    const httpResponse = await fetch(url, { headers })
    return await httpResponse.json()
  }

  private async checkConfig () {
    if (this.userWatchlistBaseUrl == null) {
      await this.loadConfig(HttpUserWatchlistService.CONFIG_URL)
    }
  }

  private async loadConfig (configUrl: string) {
    const httpResponse = await fetch(configUrl)
    const json = await httpResponse.json()
    this.userWatchlistBaseUrl = json['user-watchlist-base-url']
  }

  private createUrl (user = this.getCurrentWatchlistUser()): string {
    return this.userWatchlistBaseUrl + 'watchlists/' + user
  }
}
