import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import { getSecurityManager } from '@/security/securityManager'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/expiring-soon',
    name: 'Expiring',
    component: () => import('../views/Expiring.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coming-soon',
    name: 'Soon',
    component: () => import('../views/Soon.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: () => import('../views/Watchlist.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/persons/:personId',
    name: 'Persons',
    component: () => import('../views/Persons.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * Checks authentication requirements before routing to the next location.
 */
router.beforeEach((to, from, next) => {
  const securityManager = getSecurityManager()
  if (to.meta.requiresAuth && !securityManager.isAuthenticated()) {
    securityManager.login(next)
  } else {
    next()
  }
})

export default router
