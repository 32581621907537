import { Options, Vue } from 'vue-class-component'
import { getUserWatchlistService } from '@/services/userWatchlistService'

@Options({
  props: {
    nfid: Number,
    watchlistEntry: Object
  },
  emits: [
    'updated'
  ]
})
export default class WatchlistRating extends Vue {
  nfid!: 0n
  watchlistEntry!: Record<string, unknown>

  canEdit () {
    return getUserWatchlistService().canEditWatchlist()
  }

  async updateRating () {
    await getUserWatchlistService().updateWatchlist(this.nfid, this.watchlistEntry)
    this.$emit('updated')
  }
}
