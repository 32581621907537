import { NavigationGuardNext } from 'vue-router'
import { KeycloakSecurityManager } from '@/security/keycloakSecurityManager'

/**
 * Interface for components that can handle user authentication and authorization.
 */
export interface SecurityManager {
  /**
   * Indicates if the user is successfully authenticated.
   */
  isAuthenticated: () => boolean;

  /**
   * Redirects the user to the login page and takes care of the communication
   * with the authentication service.
   *
   * @param next The next page to redirect to after the user is logged in.
   */
  login: (next: NavigationGuardNext) => void;

  /**
   * Returns the username of the logged in user.
   */
  getUsername: () => string | undefined;

  /**
   * Logs the user out of the application.
   */
  logout: () => void;

  /**
   * Returns the JWT token
   */
  getJwtToken: () => string | undefined;
}

const securityManager = new KeycloakSecurityManager()

/**
 * Returns the security manager.
 */
export function getSecurityManager (): SecurityManager {
  return securityManager
}
